import React, { useRef,useState,useEffect } from 'react';
import { graphql, Link, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { isIOS } from 'react-device-detect';
import BrandLogo from "../../images/logo.svg";
import Notification from "../../components/Notification/Notification";
import { useLocation } from "@reach/router";
import { GetURL } from "../utils";
import MenuData from "./Menudata";
import "./Header.scss";
import ReapitBook from "../ReapitBook";
import $ from "jquery";

const Header = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                menuBurgers(where: {Publish: true}, sort: "Sort") {
                    _id
                    Parent_Label
                    Parent_Link {
                        id
                        URL
                    }
                    Sub_Menus {
                        id
                        Label
                        Link {
                            id
                            URL
                        }
                        Add_Sub_Menus {
                            id
                            Sub_Menu_Label
                            Sub_Menu_Link {
                                id
                                URL
                            }
                        }
                    }
                }
                siteConfig {
                    Phone
                    Top_Navigation_Links {
                        Label
                        Link {
                            _id
                            URL
                        }
                        Sub_Menus {
                            Label: Sub_Menu_Label
                            Link_Type
                            Anchor_or_External
                            Link: Sub_Menu_Link {
                                _id
                                URL
                            }
                        }
                    }
                }
            }
        }
    `)

    const menus = data.glstrapi.menuBurgers.map((m, k) => {
        let menu = {
            key: k,
            index: k,
            name: m.Parent_Label,
            url: m.Parent_Link != null ? GetURL(m.Parent_Link.id, m.Parent_Label) : null,
            submenu: m.Sub_Menus.map(s => ({
                name: s.Label,
                isActive: false,
                url: s.Link != null ? GetURL(s.Link.id, s.Label) : null,
                submenu: s.Add_Sub_Menus.map(asb => ({
                    name: asb.Sub_Menu_Label,
                    url: asb.Sub_Menu_Link != null ? GetURL(asb.Sub_Menu_Link.id, asb.Sub_Menu_Label) : null,
                }))
            })),
            isActive: false
        }
        return menu;
    });

    const [show, setShow] = useState(false);
    const [showMenus, setShowMenus] = useState(true);
    const [state, setState] = useState(menus);

    const location = useLocation();

    const showMenu = () => {
        setShow(!show);
    };
    const hideMenu = () => {
        setShow(!show);
        setState(
            state.map(menu =>{
                if(Array.isArray(menu.submenu)) {
                    let p =  menu.submenu.map(sub =>  ({ ...sub,...{isActive:false}}));
                    return ({...menu,...{submenu:p},...{isActive: false}})
                } else {
                    return ({...menu,...{isActive:false}})
                }
            })
        ) 
    };

    const handlerOpenMenu = (index) => {
        let newState = [...state];
        let a = newState.map((item, i) => {
            if (index !== i) {
                return { ...item, isActive: false };
            } else {
                return item;
            }
        });
        let isActive = a[index].isActive;
        a[index].isActive = !isActive;
        setState(a);
    };

    const handlerOpenSubMenu = (e, index, i) => {
        e.stopPropagation();
        let newState = [...state];
        let a = newState[index].submenu.map((item, j) => {
            if (i !== j) {
                return { ...item, isActive: false };
            } else {
                return item;
            }
        });
        newState[index].submenu = a;
        let isActive = newState[index].submenu[i].isActive;
        newState[index].submenu[i].isActive = !isActive;
        setState(newState);
    };

    const mobileNaviagationClasses = show
        ? "navigation-wrap active"
        : "navigation-wrap";

    React.useEffect(() => {
        // window.addEventListener("scroll", () => {
        //     if(window.scrollY > 500) {
        //         if(props?.menuLinks?.length > 0) {
        //             setShowMenus(true)
        //         }
        //     } else {
        //         if(props?.menuLinks?.length > 0) {
        //             setShowMenus(false)
        //         }
        //     }
        // })

        $("a[href='#']").removeAttr("href");
    }, []);

    if(typeof window !== "undefined"){
        const meta = document.createElement('meta');
        meta.name = "google-site-verification";
        meta.content = "google144100573cb56dac";
        document.getElementsByTagName('head')[0].appendChild(meta);
    }

    const [renderComponent, setRenderComponent] = useState(false);
      useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
          window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
            setRenderComponent(true)
          }
          })
          window.addEventListener("touchmove", () => {
              if (renderComponent === false) {
              setRenderComponent(true)
              }
          })
          window.addEventListener("keypress", () => {
              if (renderComponent === false) {
              setRenderComponent(true)
              }
          })}
      }, [])
 

    return (
        <header className="header">
            { renderComponent && <Notification {...props}/> }
           
            <Container fluid>
                <Row className="align-items-center">
                    <Col xs="4" lg="2" xl="1">
                        <a href={`tel:${data?.glstrapi?.siteConfig?.Phone}`} className="phone-block d-md-none cursor-pointer">
                            <i className="icon-phone"></i>
                        </a>
                        { renderComponent &&
                        <a href={'/contact/property-valuation'} className="calender-block d-none d-md-flex cursor-pointer" type="[&quot;sales&quot;,&quot;lettings&quot;,&quot;manage&quot;]">
                            <div className="btn btn-header">
                                {/* <i className="icon-calender"></i> */}
                                <span>Book a Valuation</span>
                            </div>
                        </a>
                        }
                        { !renderComponent &&
                        <div className="calender-block d-none d-md-flex cursor-pointer" >
                            <i className="icon-calender"></i>
                            <span>Book a Valuation</span>
                        </div>
                        }
                    </Col>
                    <Col xs="4" lg="8" xl="10" className="col-logo">
                        <div className="logo">
                            <Link to="/">
                                <img src={BrandLogo} alt="logo"  loading="lazy" width={50} height={32}/>
                            </Link>
                        </div>
                    </Col>
                    <Col xs="4" lg="8" xl="10" className="col-nav">
                        <nav className="main-nav">
                            <ul>
                                {showMenus && <React.Fragment>
                                    {(data?.glstrapi?.siteConfig?.Top_Navigation_Links)?.slice(0, 3).map((url, i) => 
                                        <li className="dropdown" key={i}>
                                            <Link className="dropdown-toggle" data-toggle="dropdown" role="button" to={GetURL(url?.Link?._id || url?.Sub_Menu_Link?._id)}>{url.Label || url.Sub_Menu_Label}</Link>
                                            {(url?.Sub_Menus?.length > 0) && 
                                                <ul className="dropdown-menu">
                                                    {url?.Sub_Menus.map((sub_link, k) => 
                                                        GetURL(sub_link?.Link?._id).includes("http") ?
                                                        <li className="dropdown-item" key={k}><a target="_blank" href={GetURL(sub_link?.Link?._id)}>{sub_link.Label}</a></li> :
                                                        GetURL(sub_link?.Link?._id).includes("book-a-valuation") ?
                                                        <li className="dropdown-item" key={k}>
                                                             { renderComponent &&
                                                            <ReapitBook className='cursor-pointer' type="[&quot;sales&quot;,&quot;lettings&quot;]">
                                                                {sub_link.Label} 
                                                            </ReapitBook>
                                                            }
                                                            { !renderComponent &&
                                                            <div className='cursor-pointer' >
                                                                {sub_link.Label}
                                                            </div>
                                                            }
                                                        </li> :
                                                        <li className="dropdown-item" key={k}><Link to={GetURL(sub_link?.Link?._id)}>{sub_link.Label}</Link></li>
                                                    )}
                                                </ul>
                                            }
                                        </li>
                                    )}
                                </React.Fragment>}
                                <li className="logo-li">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={BrandLogo} alt="logo" loading="lazy" width={50} height={32} />
                                        </Link>
                                    </div>
                                </li>
                                {showMenus && <React.Fragment>
                                    {(data?.glstrapi?.siteConfig?.Top_Navigation_Links)?.slice(3, 6).map((url, i) => 
                                        <li className="dropdown" key={i}>
                                            <Link className="dropdown-toggle" data-toggle="dropdown" role="button" to={GetURL(url?.Link?._id || url?.Sub_Menu_Link?._id)}>{url.Label || url.Sub_Menu_Label}</Link>
                                            {/* {(url?.Link?.menu_burgers?.length > 0 || url?.Sub_Menu_Link?.menu_burgers?.length > 0) &&  */}
                                            {(url?.Sub_Menus?.length > 0) && 
                                                <ul className="dropdown-menu">
                                                    {url?.Sub_Menus.map((sub_link, k) => 
                                                        GetURL(sub_link?.Link?._id).includes("http") ?
                                                        <li className="dropdown-item" key={k}><a target="_blank" href={GetURL(sub_link?.Link?._id)}>{sub_link.Label}</a></li> :
                                                        GetURL(sub_link?.Link?._id).includes("book-a-valuation") ?
                                                        <li className="dropdown-item" key={k}>
                                                            { renderComponent &&
                                                            <ReapitBook className='cursor-pointer' type="[&quot;sales&quot;,&quot;lettings&quot;]">
                                                                {sub_link.Label}
                                                            </ReapitBook>}
                                                            { renderComponent &&
                                                            <div className='cursor-pointer'>
                                                                {sub_link.Label}
                                                            </div>}

                                                        </li> :
                                                        <li className="dropdown-item" key={k}><Link to={GetURL(sub_link?.Link?._id)}>{sub_link.Label}</Link></li>
                                                    )}
                                                </ul>
                                            }
                                        </li>
                                    )}
                                </React.Fragment>}
                            </ul>
                        </nav>
                    </Col>
                    <Col xs="4" lg="2" xl="1">
                        <div className="menu-wrap d-flex cursor-pointer" onClick={showMenu}> 
                            <span className="menu-text">Menu</span>
                            <button className="hamburger">
                                <span className="line"></span>
                                <span className="line"></span>
                                <span className="line"></span>
                            </button>
                        </div>
                        <div className={mobileNaviagationClasses}>
                            <div className="menu-wrapper">
                                <Row className="align-items-center">
                                    <Col xs="6">
                                        <Link to="/" className="nav-logo">
                                        <img src={BrandLogo} alt="logo"  loading="lazy" width={50} height={32} />
                                        </Link>
                                    </Col>
                                    <Col xs="6" className="d-flex justify-content-end">
                                        <button type="button" className="close-btn" onClick={hideMenu}>
                                            <span className="close-text">close</span><i className="icon-close"></i>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="navigation">
                                <ul className="menu-accordion">
                                    {state.map((item, i) => (
                                        <MenuData
                                            key={i}
                                            index={i}
                                            handlerOpenMenu={handlerOpenMenu}
                                            handlerOpenSubMenu={handlerOpenSubMenu}
                                            item={item}
                                            active={location.pathname.includes(item.url)}
                                            hideMenu={hideMenu}
                                        />
                                    ))}						
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="divider-line"></div>
            </Container>
        </header>
    );
};

export default React.memo(Header);


